import React from 'react'
import Layout from './Layout'

export default function Home() {
    return (
        <Layout>
            <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-16 text-center">

                <h1 className="text-4xl font-bold tracking-tight text-gray-700 sm:text-5xl">
                    What Digital One focus on?

                </h1><br />
                <br />
                <p className="mt-6 text-lg leading-8 text-gray-600 animate-jump animate-duration-[1700ms] animate-ease-out">
                    Our comprehensive suite of offerings encompasses a diverse range of software solutions tailored to meet the unique needs of modern enterprises. From enterprise resource planning (ERP) systems to customer relationship management (CRM) platforms, our software products are designed to streamline operations, enhance efficiency, and drive growth.<br /><br />
                    In addition to software, Digital One offers a wide array of hardware solutions designed to optimize performance and productivity across diverse environments. From high-performance servers and networking equipment to state-of-the-art computing devices, our hardware offerings are engineered to deliver unmatched reliability, scalability, and performance.<br /><br />
                    At Digital One, we understand that technology is only as effective as the strategy behind its implementation. That's why we provide expert consulting services to guide our clients through every stage of their digital transformation journey. Our team of seasoned professionals works closely with clients to assess their unique challenges, identify opportunities for improvement, and develop tailored strategies to achieve their business objectives.
                    <br /><br />
                    Whether you're a small startup or a multinational corporation, Digital One is your trusted partner for all your technology needs. With our innovative solutions, unparalleled expertise, and unwavering commitment to customer satisfaction, we empower businesses to thrive in an increasingly complex and competitive landscape.
                    <br /><br />
                    Discover the power of technology with Digital One – where innovation meets excellence, and possibilities are limitless.

                </p>
                <h1 className="mt-20 text-4xl font-bold tracking-tight text-gray-700 sm:text-4xl pt-20 pb-8">
                    What we have done?
                </h1>
                <div className="mt-2 flex items-center justify-center gap-x-6">
                    <div class="bg-white py-10 sm:py-2">
                        <div class="mx-auto max-w-7xl px-6 lg:px-8">
                            <dl class="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
                                <div class="mx-auto flex max-w-xs flex-col gap-y-4">
                                    <dt class="text-base leading-7 text-gray-600">Experiences</dt>
                                    <dd class="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl">6 Years</dd>
                                </div>
                                <div class="mx-auto flex max-w-xs flex-col gap-y-4">
                                    <dt class="text-base leading-7 text-gray-600">Clients</dt>
                                    <dd class="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl">137</dd>
                                </div>
                                <div class="mx-auto flex max-w-xs flex-col gap-y-4">
                                    <dt class="text-base leading-7 text-gray-600">Projects</dt>
                                    <dd class="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl">175</dd>
                                </div>
                            </dl>
                        </div>
                    </div>

                </div>
                <h1 className="mt-20 text-4xl font-bold tracking-tight text-gray-700 sm:text-4xl pt-20 pb-8">
                    Our Precious Customer
                </h1>

                <div class="mx-auto mt-8 grid max-w-lg grid-cols-4 items-center gap-x-4 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-6 lg:mx-0 lg:max-w-none lg:grid-cols-6">
                    <img class="col-span-2 max-h-16 w-full object-contain lg:col-span-1" src="./images/logo_kemenparekraf.png" alt="Kemenparekraf" width="140" height="140"></img>
                    <img class="col-span-2 max-h-22 w-full object-contain lg:col-span-1" src="./images/logo_binus.png" alt="Bina Nusantara" width="160" height="160"></img>
                    <img class="col-span-2 max-h-16 w-full object-contain lg:col-span-1" src="./images/logo_ipb.png" alt="IPB" width="140" height="120"></img>
                    <img class="col-span-2 max-h-16 w-full object-contain lg:col-span-1" src="./images/logo-kemurnian.png" alt="Kemurnian" width="140" height="120"></img>
                    <img class="col-span-2 max-h-16 w-full object-contain lg:col-span-1" src="./images/Logo_Trans7.png" alt="Trans 7" width="140" height="140"></img>
                    <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" src="./images/logo_ochannel.png" alt="Ochannel" width="140" height="120"></img>
                    <img class="col-span-2 max-h-24 w-full object-contain lg:col-span-1" src="./images/logo_delami.png" alt="Delamibrands" width="140" height="140"></img>
                    <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" src="./images/logo_colorbox.png" alt="Colorbox" width="140" height="120"></img>
                    <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" src="./images/logo_executive.png" alt="Executive" width="140" height="120"></img>
                    <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" src="./images/logo_wood.png" alt="Wood" width="140" height="120"></img>
                    <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" src="./images/logo_wrangler.png" alt="Wrangler" width="140" height="120"></img>
                    <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" src="./images/logo_etcetera.png" alt="et cetera" width="140" height="120"></img>
                    <img class="col-span-2 max-h-18 w-full object-contain lg:col-span-1" src="./images/logo_panca.png" alt="Eatwell" width="140" height="140"></img>
                    <img class="col-span-2 max-h-16 w-full object-contain lg:col-span-1" src="./images/logo_tawan.png" alt="TaWan" width="140" height="140"></img>
                    <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" src="./images/logo_ichiban_sushi.png" alt="Ichiban Sushi" width="140" height="120"></img>
                    <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" src="./images/logo_ds.png" alt="Dapur Solo" width="140" height="120"></img>
                    <img class="col-span-2 max-h-16 w-full object-contain lg:col-span-1" src="./images/logo-tauzia.png" alt="Tauzia" width="140" height="140"></img>
                    <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" src="./images/logo_museummacan.png" alt="Museum Macan" width="140" height="120"></img>






                </div>

            </div>
        </Layout>
    )
}

